<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 357.9 123.5"
    style="enable-background: new 0 0 357.9 123.5"
    xml:space="preserve"
  >

    <g>
      <g>
        <path
          class="st0"
          d="M353.1,50.5l-62.1,0c-2.5,0-4.8-1.9-5-4.4c-0.2-2.8,2-5.2,4.8-5.2l62.1,0c2.5,0,4.8,1.9,5,4.4
			C358.1,48.1,355.9,50.5,353.1,50.5z"
        />
      </g>
      <path
        class="st1"
        d="M75.2,67.9c-6.3,0-9.5,4.9-9.5,14.8v33.9c0,2.4-1.9,4.3-4.3,4.3H46.7c-2.4,0-4.3-1.9-4.3-4.3v-34
		c0-4.2-0.7-7.6-2.2-10.4c-1.5-2.8-3.9-4.2-7.2-4.2c-6.4,0-9.6,4.9-9.6,14.8v33.9c0,2.4-1.9,4.3-4.3,4.3H4.3c-2.4,0-4.3-1.9-4.3-4.3
		V55c0-2.4,1.9-4.3,4.3-4.3H19c2.4,0,4.3,1.9,4.3,4.3v0c0,1.4,1.8,2,2.7,0.9c3.7-4.5,8.7-6.8,15.1-6.8c8.5,0,14.9,3.1,19,9.2
		c0.6,0.9,1.8,0.9,2.5,0.1c4.6-6.2,11.5-9.2,20.7-9.2c4.7,0,8.7,0.9,12.1,2.8c3.4,1.9,6,4.5,7.7,7.7c1.8,3.3,3,6.7,3.9,10.3
		c0.8,3.6,1.2,7.5,1.2,11.5v35c0,2.4-1.9,4.3-4.3,4.3H89.1c-2.4,0-4.3-1.9-4.3-4.3v-34c0-4.2-0.8-7.6-2.3-10.4
		C81,69.3,78.5,67.9,75.2,67.9z"
      />
      <path
        class="st1"
        d="M148.5,122.3c-12.6,0-22.7-3.5-30.1-10.4c-7.4-6.9-11.1-15.6-11.1-25.9c0-10.4,3.6-19.1,10.7-26.2
		c7.1-7.1,16.6-10.6,28.5-10.6c11,0,20,3.3,27,10c7,6.6,10.4,16,10.4,28c0,0.6,0,1.1,0,1.5c0,0.8-0.7,1.4-1.5,1.4l-50.9,0
		c-1,0-1.7,0.9-1.5,1.9c0.7,2.8,2.6,5.3,5.6,7.5c3.7,2.8,8.7,4.1,15,4.1c5.7,0,11.1-1.8,16.2-5.3c1.8-1.2,4.3-0.9,5.7,0.8
		c0.9,1,1.9,2.2,3.1,3.7c1.2,1.4,2.1,2.5,3,3.5c1.6,1.8,1.4,4.6-0.5,6.1C170.3,119,160.4,122.3,148.5,122.3z M146.3,66.5
		c-3.8,0-7.3,0.9-10.5,2.8c-2.6,1.5-4.4,3.6-5.3,6.3c-0.4,1,0.4,2,1.4,2h28.3c0.9,0,1.7-0.9,1.5-1.8c-0.6-2.8-2.2-5-4.8-6.6
		C153.7,67.5,150.2,66.5,146.3,66.5z"
      />
      <path
        class="st1"
        d="M183.4,116.5V55c0-2.4,1.9-4.3,4.3-4.3h14.6c2.4,0,4.3,1.9,4.3,4.3v0.5c0,1.4,1.7,2,2.6,1
		c4.5-4.9,10.6-7.4,18.3-7.4c16.5,0,24.8,10.8,24.8,32.4v35c0,2.4-1.9,4.3-4.3,4.3h-14.6c-2.4,0-4.3-1.9-4.3-4.3V82.9
		c0-4.1-0.9-7.6-2.6-10.6c-1.8-3-4.6-4.5-8.5-4.5c-3.9,0-6.7,1.4-8.5,4.2c-1.8,2.8-2.7,6.4-2.7,10.8v33.6c0,2.4-1.9,4.3-4.3,4.3
		h-14.6C185.3,120.9,183.4,118.9,183.4,116.5z"
      />
      <path
        class="st1"
        d="M338.5,59.8c-2.2-2.4-8.1-6.6-16.9-6.5c-0.4,0-0.8,0-1.2,0c-0.1,0-0.1,0-0.2,0h0c-1.6,0.1-3.4,0.4-5.2,0.9
		c-1.3,0.4-2.5,0.9-3.8,1.5c-0.2,0.1-0.4,0.2-0.6,0.3c-1.8,1-3.3,2.1-4.5,3.3c-0.3,0.3-0.6,0.6-0.9,0.9c-1.7,1.8-2.9,3.5-3.5,4.6
		c0,0,0,0,0,0c-1.3,2.2-2.2,4.5-2.9,6.9c-1,3.7-1.4,7.7-1.1,11.6v0c0.2,3.7-0.3,8.5-3,11.6c0,0,0,0,0,0c-1.1,1.6-2.5,2.7-4.3,3.4
		c-1.3,0.5-2.7,0.7-4.3,0.7c-7.4,0-11.1-5-11.1-15V55c0-2.4-1.9-4.3-4.3-4.3h-14.6c-2.4,0-4.3,1.9-4.3,4.3v35.4
		c0.6,15.4,11.3,28.2,25.7,31.9c2.8,0.7,5.8,1.1,8.8,1.1c4.7,0,9.1-0.9,13.2-2.6c11.7-4.8,20.1-15.9,21.2-29c0-0.4,0.1-0.9,0.1-1.3
		v-3.1c-0.1-1.7-0.3-3.3-0.6-5c0,0,0,0,0,0c-0.1-0.6-0.1-1.1-0.2-1.6v0c-0.4-2.6-0.2-5.2,0.8-7.7c0.2-0.5,0.4-1,0.7-1.5
		c0.3-0.6,0.7-1.3,1.2-1.9c3.6-5,9.3-6.8,14.2-5.9c1.3,0.2,2-0.6,2.3-1.1C339.5,61.5,338.9,60.2,338.5,59.8z M315.4,63.2
		c-0.8,0-1.3-0.5-1.3-0.9c0-0.2,0.1-0.3,0.2-0.4c0.3-0.3,0.8-0.5,1.2-0.4c0.5,0,0.9,0.2,1.2,0.6c0.1,0.1,0.1,0.2,0.1,0.4
		C316.8,62.8,316.2,63.2,315.4,63.2z M316.4,60.2c-1.7,0.3-3.2-0.3-3.4-1.4c-0.1-0.4,0-0.7,0.2-1c0.6-0.8,1.5-1.4,2.5-1.5
		c0.1,0,0.2,0,0.3,0c1.6-0.2,3,0.5,3.1,1.5c0,0.1,0,0.1,0,0.2C319.2,58.9,318,59.9,316.4,60.2z M332,60.2c0,0,0,0.1,0,0.1
		c-0.4,2.1-3.4,3.2-6.6,2.5c-2.6-0.5-4.6-2-5-3.7c-0.1-0.4-0.1-0.8,0-1.2c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
		c0.7-1.9,3.4-2.8,6.5-2.2C330.1,56,332.3,58.1,332,60.2z"
      />
      <g>
        <path
          class="st0"
          d="M309.2,22.8c0-2.1-0.5-4.7-3.2-4.7c-2.8,0-3.2,2.5-3.2,4.7v12c0,1.7-1.4,3.1-3.1,3.1h-4.6
			c-1.7,0-3.1-1.4-3.1-3.1V3.1c0-1.7,1.4-3.1,3.1-3.1h4.6c1.7,0,3.1,1.4,3.1,3.1v10.6c1.5-2.5,3.9-4,7.6-4c8.6,0,9.6,7.5,9.6,12v13
			c0,1.7-1.4,3.1-3.1,3.1h-4.6c-1.7,0-3.1-1.4-3.1-3.1V22.8z"
        />
        <path
          class="st0"
          d="M341,22.8c0-2.1-0.5-4.7-3.2-4.7c-2.8,0-3.2,2.5-3.2,4.7v12c0,1.7-1.4,3.1-3.1,3.1h-4.6
			c-1.7,0-3.1-1.4-3.1-3.1V13.4c0-1.7,1.4-3.1,3.1-3.1h4.6c1.7,0,3.1,1.4,3.1,3.1v0.3c1.5-2.5,3.9-4,7.6-4c8.6,0,9.6,7.5,9.6,12v13
			c0,1.7-1.4,3.1-3.1,3.1h-4.6c-1.7,0-3.1-1.4-3.1-3.1V22.8z"
        />
      </g>
    </g>
  </svg>

  <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 235.88 120.95">
    <g>
      <g>
        <path
          class="cls-1"
          d="M594.59,414.29c-5.67,0-10,3.25-10,7.56,0,4.65,3.73,7.65,9.5,7.65,5.28,0,8.82,2.64,8.82,6.57s-4.52,6.43-8.7,6.43c-4.77,0-8.51-2.82-8.51-6.43v-.26H584.4v.26c0,3.8,3.71,7.72,9.92,7.72,4.9,0,10-2.87,10-7.67,0-3.94-3.14-7.92-10.15-7.92-5,0-8.16-2.47-8.16-6.31s4.36-6.3,8.58-6.3c4.92,0,8.63,2.73,8.63,6.35v.26h1.26v-.26C604.44,417.58,600.21,414.29,594.59,414.29Z"
          transform="translate(-564.9 -323.03)"
        />
        <rect class="cls-1" x="44.62" y="91.26" width="1.33" height="29.5" />
        <polygon
          class="cls-1"
          points="75.49 118.98 53.51 91.26 51.81 91.26 51.81 120.76 53.14 120.76 53.14 93.04 75.12 120.66 75.2 120.76 76.82 120.76 76.82 91.26 75.49 91.26 75.49 118.98"
        />
        <path
          class="cls-1"
          d="M669.19,414.29H658.62v29.5H660V431.93h8.77a8.83,8.83,0,0,0,9-8.59A8.84,8.84,0,0,0,669.19,414.29Zm6.84,9a7.57,7.57,0,0,1-7.32,7.4h-8.78V415.55h8.69A7.57,7.57,0,0,1,676,423.28Z"
          transform="translate(-564.9 -323.03)"
        />
        <path
          class="cls-1"
          d="M727.51,432.13a10.32,10.32,0,0,1-3.23,7.26,10.21,10.21,0,0,1-7.42,2.86,10.36,10.36,0,0,1-10.12-10.15V414.29h-1.33V432.1a11.71,11.71,0,0,0,11.7,11.46h.27a11.7,11.7,0,0,0,11.46-11.45V414.29h-1.33Z"
          transform="translate(-564.9 -323.03)"
        />
        <path
          class="cls-1"
          d="M741.41,414.29c-5.68,0-10,3.25-10,7.56,0,4.65,3.73,7.65,9.51,7.65,5.27,0,8.82,2.64,8.82,6.57s-4.52,6.43-8.71,6.43c-4.77,0-8.51-2.82-8.51-6.43v-.26h-1.32v.26c0,3.8,3.71,7.72,9.92,7.72,4.89,0,10-2.87,10-7.68,0-3.93-3.17-7.91-10.17-7.91-5,0-8.17-2.47-8.17-6.31s4.37-6.3,8.59-6.3c4.92,0,8.62,2.73,8.62,6.35v.26h1.26v-.26C751.25,417.58,747,414.29,741.41,414.29Z"
          transform="translate(-564.9 -323.03)"
        />
        <path
          class="cls-1"
          d="M690.38,414.29h-1.15l-13.76,29.5h1.42l4.33-9.32h17.21l4.41,9.31h1.42l-13.81-29.34Zm7.42,18.89H681.85l8-17.12Z"
          transform="translate(-564.9 -323.03)"
        />
        <path
          class="cls-1"
          d="M757.83,414.09V444l23.71-14.35v-1.31Zm1.51,27.36V416.72l3.42,2L779.9,429Z"
          transform="translate(-564.9 -323.03)"
        />
      </g>
      <g>
        <path
          class="cls-1"
          d="M564.9,370.55a31,31,0,1,1,31.17,30.92h-.13A31,31,0,0,1,564.9,370.55Zm42.5,0a11.53,11.53,0,1,0-11.52,11.53,11.53,11.53,0,0,0,11.52-11.53Z"
          transform="translate(-564.9 -323.03)"
        />
        <path
          class="cls-1"
          d="M625.69,370.68A30.87,30.87,0,0,1,678.5,348.8l-13.64,13.9a11.51,11.51,0,1,0-8.25,19.58,10.06,10.06,0,0,0,8-3.48l13.9,13.69a30.06,30.06,0,0,1-21.89,9A30.73,30.73,0,0,1,625.68,371v0A2.35,2.35,0,0,0,625.69,370.68Z"
          transform="translate(-564.9 -323.03)"
        />
        <path
          class="cls-1"
          d="M741.46,372.86a41.08,41.08,0,0,0-.71-7.75c-3.09-15.82-15.58-25.47-30-25.47a23.82,23.82,0,0,0-10.83,2.61V323H679.24v76.34h0v2.09h20.65v-1.31h0V370.81c0-6.81,4.58-11.26,10.83-11.26,7,0,11.39,5.05,11.39,12.66v27.16h0v2.09h19.37v-1.31h0Z"
          transform="translate(-564.9 -323.03)"
        />
        <path
          class="cls-1"
          d="M786.9,345.32A30.81,30.81,0,0,0,770,340.26l.08,0a30.11,30.11,0,0,0-7.31.9l-.73.19a30.77,30.77,0,0,0-11,5.55,28.21,28.21,0,0,0-8.27,11,29,29,0,0,0-.31,21.77,23.64,23.64,0,0,0,2.88,5.16l0,0a19,19,0,0,0,7.86,6.12q.27.12.54.21a17.3,17.3,0,0,0,3.17.87,18.43,18.43,0,0,0,4.19.24h0c.37,0,.72-.05,1.06-.09,13.2-1.34,15.64-12.31,15.64-12.31h0a11.76,11.76,0,0,1-16.6-.73,12.48,12.48,0,0,1-1.08-1.37,11.75,11.75,0,0,1,9.23-18.3h1a11.29,11.29,0,0,1,7.34,3.16c6.13,5.84,6.47,16,2.93,23.26-.31.65-.64,1.27-1,1.87a21.44,21.44,0,0,1-17.51,9.88,18,18,0,0,1-6.31-.79s-.47-.14-1.13-.38a30.45,30.45,0,0,0,46-25.7A30.74,30.74,0,0,0,786.9,345.32Zm-22.56,39c2.46-.84,4.88-.22,5.4,1.4a.23.23,0,0,1,0,.11c.43,1.59-1.16,3.44-3.58,4.22s-4.59.29-5.28-1.13a1.53,1.53,0,0,1-.11-.27C760.27,387,761.88,385.06,764.34,384.29Zm-7.92.61c.59-.08,1.1.16,1.16.53a.47.47,0,0,1-.05.31,1.24,1.24,0,0,1-.91.56,1.31,1.31,0,0,1-1-.24.52.52,0,0,1-.16-.3C755.41,385.39,755.82,385,756.42,384.9Zm3.38,3.88a.33.33,0,0,1,0,.14c0,.83-1.08,1.46-2.33,1.46h-.23a2.82,2.82,0,0,1-2.13-1,1.13,1.13,0,0,1-.24-.81c.1-.9,1.19-1.51,2.57-1.42S759.75,388,759.8,388.78Z"
          transform="translate(-564.9 -323.03)"
        />
      </g>
    </g>
  </svg> -->
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
svg {
  position: relative;

  .cls-1 {
    fill: #fff;
  }
}

      .st0 {
        fill: #ffffff;
      }
      .st1 {
        fill: #00cdbc;
      }

</style>
